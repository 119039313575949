import React from "react";

export const Services = (props) => {
    return (
        <div id="services" className="text-center">
            <div className="container">
                <div className="section-title">
                    <h2>Çalışma Alanlarım</h2>
                    <p>
                        Profesyonel psikoloji pratiğim, bireylerin, ailelerin ve toplulukların yaşamlarında
                        karşılaştıkları zorluklarla başa çıkmalarına yardımcı olmayı amaçlamaktadır. Sunduğum hizmetler,
                        bireysel terapi, çift ve aile danışmanlığı, çocuk ve ergen psikolojisi, eğitim danışmanlığı ve
                        iş yerindeki psikolojik sağlık desteği gibi çeşitli alanları kapsar. Her bir danışanın benzersiz
                        ihtiyaçlarına uygun, bilimsel araştırmalara dayalı, özelleştirilmiş yaklaşımlar sunarak
                        zihinsel, duygusal ve davranışsal iyilik halini desteklemeyi hedefliyorum. Hayatın getirdiği
                        zorluklarla yüzleşirken bireylere rehberlik etmek ve onların daha sağlıklı, dengeli bir yaşam
                        sürmelerine olanak sağlamak için sizlere yardımcı olmaya hazırım.
                    </p>
                </div>
                <div className="row">
                    {props.data ? props.data.map((d, i) => (
                        <div key={`${d.name}-${i}`} className="col-md-4">

                            <div className={`custom-icon ${d.icon}`}
                                 style={{width: '140px', height: '140px'}}></div>

                            <div className="service-desc">
                                <h3>{d.name}</h3>
                                <p>{d.text}</p>
                            </div>
                        </div>
                    )) : "loading"}

                </div>

                <div className="section-title ekol-title">
                    <h2>EKOLLER</h2>
                    <p>
                        Psikoloji alanında, terapi uygulamalarında psikologlar tarafından kullanılan birçok ekol
                        bulunmaktadır.
                        Bu ekoller, psikolojinin farklı yönlerini ve terapi sürecinde kullanılabilecek çeşitli teknik ve
                        stratejileri temsil eder. Terapistler, genellikle bireyin veya durumun ihtiyaçlarına en uygun
                        olduğunu düşündükleri ekol veya ekollerden yararlanır.
                    </p>
                </div>
                <div className="row ekol">
                    {props.data ? props.ekol.map((d, i) => (
                        <div key={`${d.name}-${i}`} className="col-md-4 text-center">

                            <i className={d.icon}></i>
                            <div className="service-desc">
                                <h3>{d.name}</h3>
                                <p>{d.text}</p>
                            </div>
                        </div>
                    )) : "loading"}

                </div>
            </div>
        </div>
    );
};
