import React, {useState} from "react";

export const Navigation = (props) => {

  return (
      <nav id="menu" className="navbar navbar-default  navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <a className="navbar-brand page-scroll smaller-text" href="#page-top">
              Uzm. PSİKOLOG DAMLA ATASOY
            </a>
          </div>

          <div
              className="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a href="#features" className="page-scroll" >
                  TERAPİLERİM
                </a>
              </li>
              <li>
                <a href="#about" className="page-scroll" >
                  HAKKIMDA
                </a>
              </li>
              <li>
                <a href="#services" className="page-scroll" >
                  ÇALIŞMA ALANLARIM
                </a>
              </li>
              <li>
                <a href="#testimonials" className="page-scroll" >
                  SİZDEN GELENLER
                </a>
              </li>
              <li>
                <a href="#contact" className="page-scroll">
                  İLETİŞİM
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
  );
};
