import React from "react";

export const Header = (props) => {

    return (
        <header id="header" >
            <div className="intro">
                <div className="overlay">
                    <div className="container">
                        <div className="row"> {/* Ensure there is a 'row' div */}
                            <div className="col-md-12 col-sm-12 col-xs-12 intro-text">
                                <h2>
                                    {props.data ? props.data.title : "Loading"}
                                    <span></span>
                                </h2>
                                <h2>
                                    {props.data ? props.data.title2 : "Loading"}
                                    <span></span>
                                </h2>

                                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                                <p>{props.data ? props.data.paragraph2 : "Loading"}</p>

                                <a
                                    href="#contact"
                                    className="btn btn-custom btn-lg page-scroll"
                                    style={{marginTop: '1rem'}}
                                >
                                    Randevu Al
                                </a>{" "}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};
