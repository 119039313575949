import {useState} from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
    name: "",
    email: "",
    message: "",
};
export const Contact = (props) => {
    const [{name, email, message}, setState] = useState(initialState);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setState((prevState) => ({...prevState, [name]: value}));
    };
    const clearState = () => setState({...initialState});

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, message);
        emailjs
            .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_USER_ID")
            .then(
                (result) => {
                    console.log(result.text);
                    clearState();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    return (
        <div>
            <div id="contact">
                <div className="container">
                    <div className="col-md-8">
                        <div className="row">
                            <div className="section-title">
                                <h2>İLETİŞİM BİLGİLERİM</h2>
                                <p>
                                    Bilgi ve randevu için aşağıdaki iletişim bilgilerinden bana ulaşabilirsiniz.
                                </p>
                                <div className="contact-item" style={{marginBottom: '10px'}}>
                                    {props.data ? (
                                        <a href={`https://wa.me/${props.data.phone.replace(/[^0-9]/g, "")}`}
                                           target="_blank"
                                           rel="noopener noreferrer"
                                           style={{
                                               display: 'inline-block', // Ensures the link is not stretched full width
                                               backgroundColor: '#25D366', // WhatsApp color
                                               color: 'white', // Text color
                                               textDecoration: 'none', // Removes underline from the link
                                               padding: '10px 15px', // Padding inside the link, making it look like a button
                                               borderRadius: '15px', // Rounded corners for the button
                                               fontSize: '16px', // Font size
                                               fontWeight: 'bold', // Makes the text bold
                                           }}>
                                            <i className="fa fa-whatsapp" style={{marginRight: '8px'}}></i>
                                            Whatsapp İletişim
                                        </a>
                                    ) : "loading"}
                                </div>


                                <div className="contact-item">
                                    <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                                        {props.data ? props.data.email : "loading"}
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-3 col-md-offset-1 contact-info">

                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="social">
                                <ul>
                                    <li>
                                        <a href={props.data ? props.data.instagram : "/"}>
                                            <i className="fa fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={props.data ? props.data.twitter : "/"}>
                                            <i className="fa fa-twitter"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="footer">
                <div className="container text-center">
                    <p>
                        &copy; 2024 Psikolog Damla Atasoy sitesinin içeriği izinsiz kullanılamaz.
                    </p>
                </div>
            </div>
        </div>
    );
};
